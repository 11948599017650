<template>
  <a-row :gutter="16">
    <a-col :span="12">
      <a-select
        style="width: 100%"
        v-model="accountingPeriodStart"
        :placeholder="$t(`开始期间`)"
        allow-clear
        @change="onChange"
      >
        <a-select-option
          v-for="(item, index) in periodListStart"
          :key="index"
          :value="item.accounting_period_code"
        >
          {{ item.accounting_period_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="12">
      <a-select
        ref="endPeriodSelect"
        style="width: 100%"
        v-model="accountingPeriodEnd"
        :placeholder="$t(`结束期间`)"
        allow-clear
        @change="onChange"
      >
        <a-select-option
          v-for="(item, index) in periodListEnd"
          :key="index"
          :value="item.accounting_period_code"
        >
          {{ item.accounting_period_name }}
        </a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import { generatePeriods } from '@/common'
export default {
  props: {
    businessUnitCode: {
      type: String,
      default: '',
    },
    init: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      accountingPeriodStart: undefined,
      accountingPeriodEnd: undefined,
      periodList: [],
    }
  },
  mounted() {
    this.getPeriodList()
  },
  watch: {
    businessUnitCode(val) {
      if (val) {
        this.getCurrentAccountingPeriod()
      }
    },
  },
  computed: {
    periodListStart() {
      let list = this.periodList
      if (this.accountingPeriodEnd) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isBefore(this.accountingPeriodEnd) ||
            item.accounting_period_code === this.accountingPeriodEnd
        )
      }
      return list
    },
    periodListEnd() {
      let list = this.periodList
      if (this.accountingPeriodStart) {
        list = list.filter(
          (item) =>
            moment(item.accounting_period_code).isAfter(this.accountingPeriodStart) ||
            item.accounting_period_code === this.accountingPeriodStart
        )
      }
      return list
    },
  },
  methods: {
    getPeriodList() {
      http({
        url: api.queryAccountingCalendarDetailPage,
        data: {},
        success: (res) => {
          this.periodList = res.result.list
        },
      })
    },
    async getCurrentAccountingPeriod() {
      let res = await http({
        url: api.getCurrentAccountingPeriod,
        data: {
          ownerBusinessUnitCode: this.businessUnitCode,
        },
      })
      if (this.init) {
        this.accountingPeriodEnd = this.accountingPeriodStart = res.result.accounting_period_code
        this.$emit('change', [res.result.accounting_period_code])
      }
    },
    onChange() {
      if (this.accountingPeriodStart && this.accountingPeriodEnd) {
        const accountingPeriodList = generatePeriods(
          this.accountingPeriodStart,
          this.accountingPeriodEnd
        )
        this.$emit('change', accountingPeriodList)
      }
    },
    clear() {
      this.accountingPeriodStart = undefined
      this.accountingPeriodEnd = undefined
    },
  },
}
</script>
